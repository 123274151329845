// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-faq-index-en-js": () => import("./../../../src/pages/faq/index.en.js" /* webpackChunkName: "component---src-pages-faq-index-en-js" */),
  "component---src-pages-faq-index-ja-js": () => import("./../../../src/pages/faq/index.ja.js" /* webpackChunkName: "component---src-pages-faq-index-ja-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-ja-js": () => import("./../../../src/pages/index.ja.js" /* webpackChunkName: "component---src-pages-index-ja-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-en-js": () => import("./../../../src/pages/news/index.en.js" /* webpackChunkName: "component---src-pages-news-index-en-js" */),
  "component---src-pages-news-index-ja-js": () => import("./../../../src/pages/news/index.ja.js" /* webpackChunkName: "component---src-pages-news-index-ja-js" */),
  "component---src-pages-ogp-index-en-js": () => import("./../../../src/pages/ogp/index.en.js" /* webpackChunkName: "component---src-pages-ogp-index-en-js" */),
  "component---src-pages-ogp-index-ja-js": () => import("./../../../src/pages/ogp/index.ja.js" /* webpackChunkName: "component---src-pages-ogp-index-ja-js" */),
  "component---src-pages-privacy-index-en-js": () => import("./../../../src/pages/privacy/index.en.js" /* webpackChunkName: "component---src-pages-privacy-index-en-js" */),
  "component---src-pages-privacy-index-ja-js": () => import("./../../../src/pages/privacy/index.ja.js" /* webpackChunkName: "component---src-pages-privacy-index-ja-js" */),
  "component---src-pages-repairrank-foodcart-dx-index-en-js": () => import("./../../../src/pages/repairrank/foodcart-dx/index.en.js" /* webpackChunkName: "component---src-pages-repairrank-foodcart-dx-index-en-js" */),
  "component---src-pages-repairrank-foodcart-dx-index-ja-js": () => import("./../../../src/pages/repairrank/foodcart-dx/index.ja.js" /* webpackChunkName: "component---src-pages-repairrank-foodcart-dx-index-ja-js" */),
  "component---src-pages-repairrank-foodcart-ec-index-en-js": () => import("./../../../src/pages/repairrank/foodcart-ec/index.en.js" /* webpackChunkName: "component---src-pages-repairrank-foodcart-ec-index-en-js" */),
  "component---src-pages-repairrank-foodcart-ec-index-ja-js": () => import("./../../../src/pages/repairrank/foodcart-ec/index.ja.js" /* webpackChunkName: "component---src-pages-repairrank-foodcart-ec-index-ja-js" */),
  "component---src-pages-repairrank-foodcart-sh-index-en-js": () => import("./../../../src/pages/repairrank/foodcart-sh/index.en.js" /* webpackChunkName: "component---src-pages-repairrank-foodcart-sh-index-en-js" */),
  "component---src-pages-repairrank-foodcart-sh-index-ja-js": () => import("./../../../src/pages/repairrank/foodcart-sh/index.ja.js" /* webpackChunkName: "component---src-pages-repairrank-foodcart-sh-index-ja-js" */),
  "component---src-pages-repairrank-foodtruck-dx-index-en-js": () => import("./../../../src/pages/repairrank/foodtruck-dx/index.en.js" /* webpackChunkName: "component---src-pages-repairrank-foodtruck-dx-index-en-js" */),
  "component---src-pages-repairrank-foodtruck-dx-index-ja-js": () => import("./../../../src/pages/repairrank/foodtruck-dx/index.ja.js" /* webpackChunkName: "component---src-pages-repairrank-foodtruck-dx-index-ja-js" */),
  "component---src-pages-repairrank-foodtruck-ec-index-en-js": () => import("./../../../src/pages/repairrank/foodtruck-ec/index.en.js" /* webpackChunkName: "component---src-pages-repairrank-foodtruck-ec-index-en-js" */),
  "component---src-pages-repairrank-foodtruck-ec-index-ja-js": () => import("./../../../src/pages/repairrank/foodtruck-ec/index.ja.js" /* webpackChunkName: "component---src-pages-repairrank-foodtruck-ec-index-ja-js" */),
  "component---src-pages-repairrank-foodtruck-sh-index-en-js": () => import("./../../../src/pages/repairrank/foodtruck-sh/index.en.js" /* webpackChunkName: "component---src-pages-repairrank-foodtruck-sh-index-en-js" */),
  "component---src-pages-repairrank-foodtruck-sh-index-ja-js": () => import("./../../../src/pages/repairrank/foodtruck-sh/index.ja.js" /* webpackChunkName: "component---src-pages-repairrank-foodtruck-sh-index-ja-js" */),
  "component---src-pages-repairrank-index-en-js": () => import("./../../../src/pages/repairrank/index.en.js" /* webpackChunkName: "component---src-pages-repairrank-index-en-js" */),
  "component---src-pages-repairrank-index-ja-js": () => import("./../../../src/pages/repairrank/index.ja.js" /* webpackChunkName: "component---src-pages-repairrank-index-ja-js" */),
  "component---src-pages-terms-index-en-js": () => import("./../../../src/pages/terms/index.en.js" /* webpackChunkName: "component---src-pages-terms-index-en-js" */),
  "component---src-pages-terms-index-ja-js": () => import("./../../../src/pages/terms/index.ja.js" /* webpackChunkName: "component---src-pages-terms-index-ja-js" */),
  "component---src-pages-thanks-index-en-js": () => import("./../../../src/pages/thanks/index.en.js" /* webpackChunkName: "component---src-pages-thanks-index-en-js" */),
  "component---src-pages-thanks-index-ja-js": () => import("./../../../src/pages/thanks/index.ja.js" /* webpackChunkName: "component---src-pages-thanks-index-ja-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

