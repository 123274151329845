import "./src/styles/global.css"
// import "./src/styles/animate.css"
// import "./src/styles/sp.css"
const $ = require("jquery")
import {globalHistory} from '@reach/router';

export const onInitialClientRender = () => {
    $(document).ready(function () {
        //console.log("here loading jQuery confirmed")
    });
    window.addEventListener('popstate', () =>
        window.location.href = window.location.href
    )
}